@import '../../../variables.scss';

.stepsCards {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  margin-top: 240px;

  @media (max-width: $screen-md) {
    row-gap: 50px;
    margin-top: 100px;
  }
}

.stepsCard {
  display: flex;
  column-gap: 140px;

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @media (max-width: $screen-lg) {
    column-gap: 40px;
  }

  @media (max-width: $screen-md) {
    flex-direction: column;
    row-gap: 40px;

    &:nth-child(2n) {
      flex-direction: column;
    }
  }
}

.stepsCardImage {
  width: 624px;
  height: 563px;
  border-radius: 28px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @media (max-width: $screen-xl) {
    width: 400px;
    height: 364px;
  }

  @media (max-width: $screen-sm) {
    width: 100%;
    height: auto;
  }
}

.stepsCardData {
  display: flex;
  flex-direction: column;
}

.stepsCardCount {
  font-family: 'Fira Code', monospace;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: $screen-sm) {
    display: none;
  }
}

.stepsCardTitle {
  font-family: 'Fira Code', monospace;
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  margin: 56px 0 40px;
  color: #fff;

  @media (max-width: $screen-sm) {
    font-size: 24px;
    line-height: 36px;
    margin: 40px 0 28px;
    text-align: center;
    margin: 0 0 20px;
  }
}

.stepsCardText {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: $gray;

  @media (max-width: $screen-sm) {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
}
