@import '../../../variables.scss';

%form-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 32px;
  color: #fff;
  display: block;
  font-family: 'Fira Code', monospace;
  text-align: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.7;
  }
}

.form {
  position: relative;
  width: 1000px;
  max-height: 100%;
  max-width: 100%;
  padding: 100px 200px;
  border-radius: 20px;
  background: #252533;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: $screen-xxl) {
    width: 800px;
    padding: 50px 80px;
  }

  @media (max-width: $screen-md) {
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    border-radius: none;
    padding: 60px;
    transform: none;
    border-radius: 0;
    background-color: #000;
  }

  @media (max-width: $screen-xs) {
    padding: 20px;
  }

  &-title {
    @extend %form-title;
    margin-bottom: 52px;

    @media (max-width: $screen-xxl) {
      margin-bottom: 40px;
    }

    @media (max-width: $screen-xs) {
      margin-bottom: 24px;
      font-size: 24px;
      margin-top: 24px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

  &-close {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 30px;
    right: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    z-index: 2;

    svg {
      width: 20px;
    }

    path {
      transition: 0.2s;
      stroke: #fff !important;
    }

    @media (max-width: $screen-sm) {
      top: 20px;
      right: 20px;
    }

    @media (max-width: $screen-xs) {
      top: 12px;
      right: 12px;
    }
  }

  &-label {
    position: relative;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #81819a;
    margin-bottom: 36px;

    span {
      position: absolute;
      bottom: 13px;
      left: 28px;
      pointer-events: none;
    }

    svg {
      position: absolute;
      top: 6px;
      left: 0;
      pointer-events: none;
    }

    @media (max-width: $screen-xxl) {
      margin-bottom: 20px;
      font-size: 16px;

      span {
        bottom: 8px;
      }
    }

    @media (max-width: $screen-xs) {
      span {
        bottom: 14px;
        font-size: 14px;
      }
    }

    @media (max-width: $screen-xs) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }

  &-error {
    position: absolute;
    font-size: 11px;
    bottom: -5px !important;
    left: 40px;
    opacity: 0;
    color: #ee6328;

    @media (max-width: $screen-xs) {
      font-size: 10px;
      bottom: -1px !important;
    }
  }

  &-input {
    width: 100%;
    border: none;
    background: #323247;
    height: 60px;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #fff;
    padding: 0 0 0 28px;
    box-sizing: border-box;
    resize: none;
    border-radius: 12px;

    &:focus {
      outline: none;

      & + span {
        display: none;
      }

      & ~ .form-error {
        opacity: 0 !important;
      }
    }

    &:valid {
      & ~ svg {
        path {
          stroke: var(--page-color);
        }
      }
    }

    &:invalid {
      & ~ .form-error {
        opacity: 1;
      }
    }

    &:not(:placeholder-shown) {
      & + span {
        display: none;
      }
    }

    &:placeholder-shown {
      & ~ svg {
        path {
          stroke: #a4acc2;
        }
      }

      & ~ .form-error {
        opacity: 0 !important;
      }
    }

    @media (max-width: $screen-xxl) {
      height: 50px;
      font-size: 16px;
    }

    @media (max-width: $screen-xs) {
      height: 50px;
    }
  }

  .form-textarea {
    height: 270px !important;

    @media (max-width: $screen-xxl) {
      height: 190px !important;
    }
  }

  textarea + span {
    bottom: unset !important;
    top: 12px;
    right: 28px;

    @media (max-width: $screen-md) {
      top: 10px;
      right: 28px;
    }
  }

  %form-button {
    position: relative;
    cursor: pointer;
    height: 76px;
    background: $green;
    border: none;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-radius: 12px;
    font-family: 'Fira Code', monospace;
    margin: auto 0 0;
  }

  &-button {
    @extend %form-button;
    transition: 0.2s;

    &:hover {
      background-color: $green-05;

      .submit-icon {
        transform: translateX(15px);
      }
    }

    @media (max-width: $screen-xxl) {
      height: 52px !important;
      font-size: 20px !important;
    }

    @media (max-width: $screen-xs) {
      height: 50px !important;
      font-size: 16px !important;

      &:hover {
        .submit-icon {
          transform: translateX(10px);
        }
      }
    }
  }

  &-success {
    align-items: center;
  }
}

.success {
  position: absolute;
  top: 32px;
  left: 50%;
  background-color: #fff;
  padding: 12px 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  transform: translate(-50%, 0);
  transition: 0.8s;
}

.success-text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.submit-icon {
  width: 36px;
  transition: 0.2s;

  @media (max-width: $screen-xs) {
    width: 26px;
  }
}

.popup-success {
  animation: hide 3.5s;
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.form-type-holder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.form-type {
  padding: 12px 0;
  width: calc((100% - 32px) / 3);
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: #81819a;
  background: #2c2c3d;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #54546d;
    color: #000;
  }

  &-active {
    color: #000 !important;
    background-color: #0effd4 !important;
  }

  @media (max-width: $screen-md) {
    width: calc((100% - 64px) / 3);
  }

  @media (max-width: $screen-xs) {
    width: calc((100% - 24px) / 3);
  }
}
