@import '../../../variables.scss';

.fullButton {
  cursor: pointer;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  font-size: 44px;
  font-weight: 600;
  line-height: 60px;
  font-family: 'Fira Code', monospace;
  position: relative;
  z-index: 10;

  svg {
    width: 55px;
    transition: 0.2s;
  }

  .content {
    display: flex;
    justify-content: space-between;

    &:before {
      display: none !important;
    }
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }

  @media (max-width: $screen-md) {
    padding: 28px 0;
    font-size: 28px;
    line-height: 40px;

    svg {
      width: 36px;
    }
  }

  @media (max-width: $screen-sm) {
    padding: 20px 0;
  }
}
