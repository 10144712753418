@import '../../../variables.scss';

.nursingServicesApplyHolder {
  position: relative;
  display: flex;
  align-items: center;
  padding: 32px 32px 0;
  border-radius: 28px;
  background: #17171f;
  flex-grow: 1;
  margin-bottom: 20px;

  @media (max-width: $screen-sm) {
    flex-direction: column;
  }

  @media (max-width: $screen-xs) {
    padding: 24px 24px 0;
  }
}

.serviceCardButton {
  cursor: pointer;
  border-radius: 12px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  display: flex;
  column-gap: 40px;
  width: 260px;
  height: 48px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: 'Fira Code', monospace;

  @media (max-width: $screen-sm) {
    margin-bottom: 24px;
  }
}
