@import '../../../variables.scss';

.pioneersSection {
  @extend %fullSection;
  background-color: #000;
  padding: 180px 0;
  width: 100%;

  @media (max-width: $screen-md) {
    padding: 100px 0;
  }

  @media (max-width: $screen-xs) {
    padding: 60px 0;
  }
}

.pioneersTitle {
  @extend %h1;
  color: #fff;
  margin: 0 auto 0;
}

.pioneersText {
  max-width: 986px;
  color: #fff;
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  display: inline-block;
  margin: 80px auto;

  @media (max-width: $screen-sm) {
    font-size: 16px;
    line-height: 20px;
    margin: 24px auto;
  }
}

.pioneersCardsHolder {
  width: 100%;
  display: flex;
  column-gap: 12px;
  row-gap: 12px;
  padding-bottom: 100px;
  border-bottom: 1px solid #1d1d21;

  @media (max-width: $screen-lg) {
    flex-wrap: wrap;
  }

  @media (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.pioneersCard {
  position: relative;
  height: 502px;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  padding: 28px;
  max-width: calc((100% - 36px) / 4);
  box-sizing: border-box;
  border-radius: 12px;
  align-items: start;
  text-align: left;
  z-index: 1;
  overflow: hidden;

  @media (max-width: $screen-lg) {
    max-width: calc((100% - 12px) / 2);
  }

  @media (max-width: $screen-md) {
    height: 460px;
  }

  @media (max-width: $screen-sm) {
    max-width: 100%;
    height: 380px;
  }

  @media (max-width: $screen-xs) {
    min-height: unset;
    padding-top: 68px;
    height: auto;
  }
}

.pioneersCardBack {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0;
}

.pioneersCardCount {
  position: absolute;
  top: 28px;
  right: 28px;
  font-family: 'Fira Code', monospace;
  font-weight: 500;
  font-size: 20px;
}

.pioneersCardTitle {
  font-size: 30px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  margin-bottom: 36px;

  @media (max-width: $screen-xl) {
    font-size: 26px;
    line-height: 30px;
  }

  @media (max-width: $screen-xs) {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}

.pioneersCardText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.pioneersTrust {
  padding: 100px 0 50px;
  width: 100%;
  border-bottom: 1px solid #1d1d21;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-of-type {
    border: none;
  }

  @media (max-width: $screen-md) {
    padding: 30px 0 20px;
  }
}

.pioneersTrustTitle {
  display: inline-block;
  margin: 0 0 40px;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  color: #fff;
  max-width: 980px;

  @media (max-width: $screen-md) {
    max-width: 90%;
  }

  @media (max-width: $screen-xs) {
    font-size: 24px;
    line-height: 30px;
  }
}

.universitiesOverlay {
  position: relative;
  width: calc(100vw - 17px);
  margin-left: 0px;
  overflow: hidden;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 172px;
    content: '';
    z-index: 1;
  }

  &:before {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 100%);
    left: -1px;
  }

  &:after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 100%);
    right: -1px;
  }

  @media (max-width: $screen-md) {
    width: calc(100% + 80px);

    &:before,
    &:after {
      display: none;
    }
  }

  @media (max-width: $screen-xs) {
    width: calc(100% + 40px);
  }
}

.universitiesHolder {
  width: 100%;
  margin-left: 60px;
  overflow: hidden;
  margin: 72px 0 0;

  @media (max-width: $screen-xs) {
    margin: 12px 0 0;
  }
}

.universitiesHolderRow {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 25px;
  margin-bottom: 15px;

  &:nth-child(2n + 1) {
    .universityBox {
      animation: scroll 80s linear infinite;
    }
  }

  &:nth-child(2n) {
    .universityBox {
      animation: scroll 80s linear infinite;
      animation-direction: reverse;
    }
  }
}

.universityBox {
  flex-shrink: 0;
  display: flex;
  gap: 12px;
  counter-reset: item;
  justify-content: space-around;
  min-width: 100%;
}

.university {
  height: 160px;
  width: 266px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 95%;
  }

  @media (max-width: $screen-lg) {
    height: 100px;
    width: 180px;

    img {
      max-width: 90%;
    }
  }

  @media (max-width: $screen-xs) {
    height: 80px;
    width: 100px;
  }
}

.aboutButton {
  background-color: #252533;
  color: #fff;
  height: 76px;
  padding: 0 60px;
  width: 100%;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  font-size: 30px;
  line-height: 32px;
  margin-top: 24px;
  transition: 0.2s;

  &:hover {
    background-color: #32334a;

    .aboutButtonIcon {
      transform: translateX(15px);
    }
  }

  @media (max-width: $screen-sm) {
    font-size: 16px;
    padding: 16px 20px;
    margin-top: 12px;
    height: 60px;
    transition: 0.2s;

    &:hover {
      .aboutButtonIcon {
        transform: translateX(10px);
      }
    }
  }
}

.aboutButtonIcon {
  width: 36px;
  transition: 0.2s;

  @media (max-width: $screen-sm) {
    width: 26px;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}
