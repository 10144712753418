@import '../../../variables.scss';

.productSection {
  @extend %fullSection;
  background-color: #000;
  position: relative;
  z-index: 1;
  margin-top: 300px;

  &::before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    right: 0;
    height: 350px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 100%);
  }

  &:after {
    top: 0;
    bottom: 0;
    position: absolute;
    content: '';
    z-index: -1;
  }

  @media (max-width: $screen-md) {
    &:before,
    &:after {
      left: -40px;
      right: -40px;
    }

    &:after {
      background-color: #000;
    }
  }

  @media (max-width: $screen-sm) {
    margin-top: 120px;

    &:before {
      height: 200px;
    }
  }

  @media (max-width: $screen-xs) {
    &:before,
    &:after {
      left: -20px;
      right: -20px;
    }
  }
}

.productTitle {
  @extend %h1;
  color: #fff;
  margin-top: 78px;

  @media (max-width: $screen-xs) {
    margin-top: 0;
  }
}

.productText {
  max-width: 986px;
  color: #fff;
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  display: inline-block;
  margin: 80px auto;

  @media (max-width: $screen-sm) {
    font-size: 16px;
    line-height: 20px;
    margin: 0 auto 24px;
  }
}

.productHolder {
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 36px 68px 0;
  background-color: #252533;
  width: 100%;
  height: 920px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: $screen-xs) {
    border-radius: 0;
    padding: 36px 28px 0;
    margin: 0 -20px;
    width: calc(100% + 40px);
    background: none;
    height: 752px;
  }
}

.productButtonsHolder {
  @media (max-width: $screen-lg) {
    width: calc(100% + 136px);
    height: 60px;
    margin-left: -68px;
    overflow: hidden;
    margin-bottom: 40px;
  }

  @media (max-width: $screen-xs) {
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-bottom: 16px;
  }
}

.productButtons {
  display: flex;
  column-gap: 12px;
  margin-bottom: 60px;
  justify-content: center;

  @media (max-width: $screen-lg) {
    width: auto;
    overflow: auto;
    padding-bottom: 20px;
    justify-content: start;
    padding-left: 40px;
  }

  @media (max-width: $screen-xs) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.productButton {
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  width: 300px;
  background-color: #323247;
  color: #81819a;
  padding: 20px 0;
  border-radius: 8px;
  font-size: 18px;
  flex-shrink: 0;
  font-weight: 500;

  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    background-color: $green;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: translateX(-110%);
  }

  &:hover {
    background-color: #3f405e;
    color: #fff;
  }

  &-active {
    color: #252533;
    background-color: $green-05;

    &:before {
      opacity: 1;
      animation: slide 5s linear;
    }

    &:hover {
      color: #252533;
      background-color: $green;
    }
  }

  @media (max-width: $screen-xxl) {
    width: 264px;
    padding: 16px 0;
    font-size: 16px;
  }

  @media (max-width: $screen-xl) {
    width: 238px;
  }

  @media (max-width: $screen-md) {
    width: 224px;
  }

  @media (max-width: $screen-xs) {
    width: 196px;
    padding: 12px 0;
  }
}

.productScreen {
  position: absolute;
  bottom: 0;
  left: 110px;
  right: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: 0.3s;
  transform: translateX(-50px);

  @media (max-width: $screen-lg) {
    left: 25px;
    right: 25px;
  }

  @media (max-width: $screen-md) {
    left: 0;
    right: 0;
    top: 180px;
  }

  @media (max-width: $screen-sm) {
    max-height: calc(100% - 375px);
    top: 120px;
  }

  .productScreen-active + & {
    transform: translateX(50px);
  }
}

.productScreen-active {
  opacity: 1;
  transform: translateX(0);
}

.productScreenTitle {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  font-family: 'Fira Code', monospace;

  @media (max-width: $screen-xs) {
    font-size: 26px;
    max-width: 95%;
  }
}

.productScreenText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  margin: 32px 0 68px;
  max-width: 675px;
  color: #fff;
  text-align: center;

  @media (max-width: $screen-xs) {
    max-width: 90%;
    margin: 24px 0;
  }
}

.productScreenImage {
  height: 536px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: 100% 100%;
  flex-shrink: 1;

  @media (max-width: $screen-sm) {
    height: auto;
    width: 100%;
  }
}

@keyframes slide {
  from {
    transform: translateX(-101%);
  }
  to {
    transform: translateX(0);
  }
}
