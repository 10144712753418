@import '../../../variables.scss';

.marketersServices {
  display: flex;
  flex-direction: column;

  .mainPageSectionTitle {
    margin: 240px auto 80px;
  }

  @media (max-width: $screen-xs) {
    .mainPageSectionTitle {
      margin: 100px auto 40px;
    }
  }
}

.marketersServicesRow {
  display: flex;
  margin-bottom: 20px;
  column-gap: 20px;

  @media (max-width: $screen-xl) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.marketersHow {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.marketersHowHolder {
  @extend %fullSection;
  margin: 240px 0 0;
  padding: 140px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #17171f;
  width: calc(100vw - 17px);
  height: auto !important;
  min-height: unset;

  .mainPageSectionTitle {
    margin-bottom: 100px;
  }

  @media (max-width: $screen-xs) {
    padding: 52px 0;
    margin: 60px 0 0;
    .mainPageSectionTitle {
      margin-bottom: 40px;
    }
  }
}

.marketersHowSteps {
  display: flex;
  column-gap: 20px;

  @media (max-width: $screen-lg) {
    flex-direction: column;
    row-gap: 80px;
    align-items: center;
  }

  @media (max-width: $screen-xs) {
    row-gap: 40px;
  }
}

.marketersHowArrow {
  width: 55px;
  height: 36px;
  flex-shrink: 0;
  display: inline-block;
  margin-top: 24px;

  @media (max-width: $screen-lg) {
    display: none;
  }
}

.marketersHowStep {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 30%;
  width: 30%;

  @media (max-width: $screen-xl) {
    max-width: 320px;
  }

  @media (max-width: $screen-lg) {
    width: 100%;
    flex-basis: 100%;
    max-width: 500px;
  }

  @media (max-width: $screen-xs) {
    max-width: calc(100% - 40px);
  }
}

.marketersHowStepCount {
  font-family: 'Fira Code', monospace;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 38px;
  font-weight: 700;
  line-height: 72px;

  @media (max-width: $screen-lg) {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }

  @media (max-width: $screen-xs) {
    width: 40px;
    height: 40px;
    font-size: 22px;
  }
}

.marketersHowStepTitle {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  display: inline-block;
  margin: 40px 0 20px;

  @media (max-width: $screen-xs) {
    margin: 20px 0 16px;
    font-size: 20px;
  }
}

.marketersHowStepText {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #7a868f;

  @media (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 24px;
  }
}

.marketersExpertsHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .mainPageSectionTitle {
    margin-bottom: 80px;
  }

  @media (max-width: $screen-xs) {
    .mainPageSectionTitle {
      margin-bottom: 40px;
    }
  }
}

.marketersExpertsSliderHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 240px;
  height: 528px;
  overflow: hidden;

  @media (max-width: $screen-xs) {
    margin-bottom: 100px;
  }
}

.marketersExpertsSlider {
  @extend %fullSection;
  min-height: unset;
  overflow: hidden;
  align-items: flex-start;
  margin-bottom: -25px;
  max-width: calc(100vw - 17px);
}

.marketersExperts {
  display: flex;
  column-gap: 16px;
  overflow: auto;
  padding: 0 0 25px;
  width: 100%;
  padding: 0 0 25px;

  &:before,
  &:after {
    content: '';
    height: 100%;
    width: 100px;
    display: inline-block;
  }

  @media (max-width: $screen-xxl) {
    &:before,
    &:after {
      width: calc((100vw - 1280px) / 2);
      min-width: calc((100vw - 1280px) / 2);
    }
  }

  @media (max-width: $screen-xl) {
    &:before,
    &:after {
      width: calc((100vw - 1020px) / 2);
      min-width: calc((100vw - 1020px) / 2);
    }
  }

  @media (max-width: $screen-lg) {
    &:before,
    &:after {
      width: calc((100vw - 960px) / 2);
      min-width: calc((100vw - 960px) / 2);
    }
  }

  @media (max-width: $screen-md) {
    &:before,
    &:after {
      width: 40px;
    }
  }

  @media (max-width: $screen-xs) {
    &:before,
    &:after {
      width: 20px;
    }
  }
}

.marketersExpert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 32px;
  border-radius: 28px;
  background: #17171f;
  min-width: 320px;

  @media (max-width: $screen-xs) {
    min-width: 260px;
  }
}

.marketersExpertHeader {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.marketersExpertLogo {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.marketersExpertName {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #fff;
}

.marketersExpertDetails {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 28px 0;
  border-bottom: 1px solid #303030;
  width: 100%;
}

.marketersExpertDetail {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  display: flex;
  column-gap: 8px;
}

.marketersExpertSkills {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin: 28px 0;
}

.marketersExpertSkill {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  display: flex;
  column-gap: 8px;
}

.marketersExpertLanguages {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 28px;
}

.marketersExpertLanguage {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #9d9da7;
  border-radius: 10px;
  padding: 4px 12px;
  background: #2c2c3d;
  align-self: flex-start;
  display: flex;
  column-gap: 12px;
  align-items: center;

  img {
    width: 20px;
    height: 14px;
    object-fit: contain;
  }
}

.marketersExpertButton {
  align-self: stretch;
  font-family: 'Fira Code', monospace;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  margin: auto 0 0 0;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    opacity: 0.8;
  }
}

.marketersWhyHolder {
  display: flex;
  flex-direction: column;
  margin-bottom: 240px;

  .mainPageSectionTitle {
    margin-bottom: 80px;
    text-align: center;
  }

  @media (max-width: $screen-xs) {
    margin-bottom: 100px;
    .mainPageSectionTitle {
      margin-bottom: 40px;
    }
  }
}

.marketersWhyStats {
  border-radius: 28px;
  padding: 60px 72px;
  display: flex;
  background-color: #17171f;
  column-gap: 100px;

  @media (max-width: $screen-xxl) {
    column-gap: 80px;
  }

  @media (max-width: $screen-xl) {
    column-gap: 60px;
  }

  @media (max-width: $screen-lg) {
    column-gap: 50px;
  }

  @media (max-width: $screen-md) {
    flex-direction: column;
    row-gap: 50px;
  }

  @media (max-width: $screen-xs) {
    padding: 52px;
  }
}

.marketersWhyStat {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 16px;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: calc((100% - 300px) / 4);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -50px;
    width: 1px;
    background-color: #303030;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  @media (max-width: $screen-xxl) {
    max-width: calc((100% - 240px) / 4);

    &:after {
      right: -40px;
    }
  }

  @media (max-width: $screen-xl) {
    max-width: calc((100% - 180px) / 4);

    &:after {
      right: -30px;
    }
  }

  @media (max-width: $screen-lg) {
    max-width: calc((100% - 150px) / 4);

    &:after {
      right: -25px;
    }
  }

  @media (max-width: $screen-md) {
    max-width: 100%;
    width: 100%;

    &:after {
      display: none;
      // top: unset;
      // bottom: -25px;
      // left: 0;
      // right: 0;
      // height: 1px;
      // width: 100%;
    }
  }
}

.marketersWhyStatCount {
  font-family: 'Fira Code', monospace;
  font-size: 60px;
  font-weight: 500;
  line-height: 92px;

  @media (max-width: $screen-xl) {
    font-size: 46px;
  }

  @media (max-width: $screen-lg) {
    font-size: 36px;
  }

  @media (max-width: $screen-xs) {
    font-size: 46px;
  }
}

.marketersWhyStatText {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #fff;

  @media (max-width: $screen-xl) {
    font-size: 22px;
  }

  @media (max-width: $screen-lg) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: $screen-xs) {
    font-size: 22px;
    line-height: 36px;
  }
}

.marketersReviews {
  @media (max-width: $screen-xs) {
    margin-bottom: 80px !important;
  }
}

.marketersTrusted {
  @media (max-width: $screen-xs) {
    padding-bottom: 40px !important;
  }
}
