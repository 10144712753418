@import '../../../variables.scss';

.blogContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: $screen-xl) {
    max-width: calc(100% - 64px);
  }
}

.blogPosts {
  display: flex;
  column-gap: 32px;
  row-gap: 32px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 32px;

  @media (max-width: $screen-xl) {
    column-gap: 24px;
    row-gap: 24px;
  }
}

.blogContentTitle {
  font-family: Fira Code;
  font-size: 44px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  margin-top: 132px;
  margin-bottom: 52px;
  color: #fff;
  width: 100%;
}

.blogPostCard {
  width: calc((100% - 64px) / 3);
  padding: 12px 12px 28px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 28px;
  background: #17171f;
  cursor: pointer;
  align-self: stretch;
  transition: 0.15s;

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: $screen-xl) {
    width: calc((100% - 24px) / 2);
  }

  @media (max-width: $screen-md) {
    width: 100%;
  }
}

.blogPostCardImage {
  position: relative;
  padding-bottom: 54%;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid #252532;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
}

.blogPostCardTitle {
  color: #fff;
  font-family: Fira Code;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: inline-block;
  margin: 20px 16px 16px;
}

.blogPostCardDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: #767e92;
  display: inline-block;
  margin: 0 16px;
}
