@import '../../../variables.scss';

.reviewsHolder {
  display: flex;
  column-gap: 72px;
  margin-bottom: 200px;
  position: relative;
  z-index: 100;

  @media (max-width: $screen-lg) {
    flex-direction: column;
    row-gap: 72px;
  }

  @media (max-width: $screen-xs) {
    row-gap: 44px;
  }
}

.reviewCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% - 144px) / 3);

  @media (max-width: $screen-lg) {
    width: 100%;
  }
}

.reviewCardLogo {
  height: 88px;

  svg,
  img {
    height: 100%;
  }

  @media (max-width: $screen-xs) {
    height: 72px;
  }
}

.reviewCardText {
  margin: 36px 0 44px;
  color: $gray;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;

  @media (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 28px;
  }

  span {
    color: #fff;
  }
}

.reviewCardData {
  display: flex;
  column-gap: 20px;
}

.reviewCardPhoto {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.reviewCardNameData {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: $gray;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;

  @media (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 28px;
  }
}
