@import '../../variables.scss';

html,
body {
  background-color: #000;
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  text-decoration: none !important;
}

.fullScreenSection {
  @extend %fullSection;
}

.videoBack {
  pointer-events: none;
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  max-width: 984px;
  width: 55%;
  height: auto;
  z-index: 0;
  margin-top: 56px;

  @media (max-width: $screen-sm) {
    margin-top: 0;
    width: 150vw;
  }
}

.missionSection {
  @media (max-width: $screen-xs) {
    min-height: unset;
  }
}

.companyScreenSection {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 120px 0;
  background-color: #000;
  position: relative;
  z-index: 1;

  @media (max-width: $screen-xs) {
    min-height: unset;
    padding: 60px 0 0;
  }
}

.companyScreenDetails {
  text-align: center;
  font-size: 30px;
  line-height: 42px;
  font-weight: 500;
  color: #fff;
  max-width: 986px;
  font-family: 'Fira Code', monospace;

  span {
    color: #0effd4;
  }

  @media (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 20px;
  }
}

.secondCreenDetails {
  text-align: center;
  font-size: 40px;
  line-height: 56px;
  color: #fff;
  max-width: 1060px;

  span {
    color: #0effd4;
    font-family: 'Fira Code', monospace;
  }

  @media (max-width: $screen-xxl) {
    max-width: 986px;
  }

  @media (max-width: $screen-xs) {
    font-size: 20px;
    line-height: 26px;
  }
}

.teamSection {
  background-color: #000;
  position: relative;
  z-index: 1;
  padding-top: 52px;
}

.teamsHolder {
  @media (max-width: $screen-lg) {
    display: flex;
    column-gap: 64px;
  }

  @media (max-width: $screen-md) {
    flex-direction: column;
    row-gap: 32px;
  }
}

.teamItem {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 45%;
  padding-top: 120px;
  padding-bottom: 120px;
  border-bottom: 1px solid #1d1d21;

  &:nth-child(2) {
    padding-left: 55%;
    padding-right: 0;

    @media (max-width: $screen-xl) {
      padding-left: 48%;
    }

    .teamPhoto {
      right: unset;
      left: 20px;

      @media (max-width: $screen-xl) {
        left: 0;
      }
    }
  }

  @media (max-width: $screen-lg) {
    flex-direction: column;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (max-width: $screen-md) {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  @media (max-width: $screen-sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: $screen-xs) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.teamName {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 16px;
  font-family: 'Fira Code', monospace;

  @media (max-width: $screen-xl) {
    font-size: 30px;
  }
}

.teamPhoto {
  position: absolute;
  top: 170px;
  right: 0;
  width: 42%;
  height: auto;
  object-fit: contain;

  @media (max-width: $screen-xl) {
    right: 20px;
    width: 36%;
    right: 20px;
  }

  @media (max-width: $screen-lg) {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  @media (max-width: $screen-md) {
    width: 75%;
    margin: 0 auto;
  }
}

.teamRole {
  font-size: 20px;
  font-weight: 500;
  color: #fff;

  @media (max-width: $screen-xl) {
    font-size: 16px;
  }
}

.teamTags {
  display: flex;
  column-gap: 12px;
  row-gap: 12px;
  margin: 28px 0;
  flex-wrap: wrap;
}

.teamTag {
  color: #ababab;
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;
  white-space: nowrap;

  @media (max-width: $screen-xl) {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.teamLink,
.teamAdvisorLink {
  color: #0effd4;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: #0effd4;
    margin-right: 8px;
  }
}

.teamText {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  margin: 66px 0;
  max-width: 568px;

  span {
    display: inline-block;
    margin-bottom: 24px;
  }

  .teamTextMore,
  .teamTextLess {
    position: relative;
    cursor: pointer;
    transition: 0.2s;

    &:before {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      left: calc(100% + 16px);
      border-top: 1px solid currentColor;
      border-left: 1px solid currentColor;
    }

    &:hover {
      color: $green;
    }
  }

  .teamTextLess {
    &:before {
      top: 14px;
      transform: rotate(45deg);
    }
  }

  .teamTextMore {
    &:before {
      top: 10px;
      transform: rotate(-135deg);
    }
  }

  a {
    cursor: pointer;
    color: $green;

    &:hover,
    &:active,
    &:visited {
      color: $green;
    }
  }

  @media (max-width: $screen-xl) {
    max-width: 472px;
    margin: 56px 0;

    .teamTextLess {
      &:before {
        top: 10px;
      }
    }

    .teamTextMore {
      &:before {
        top: 7px;
        transform: rotate(-135deg);
      }
    }
  }

  @media (max-width: $screen-md) {
    max-width: unset;
    width: 100%;
  }
}

.teamAdvisors {
  display: flex;
  column-gap: 60px;
  margin-top: 120px;
  overflow: auto;
  justify-content: center;

  @media (max-width: $screen-md) {
    margin-top: 80px;
    padding-bottom: 30px;
    padding-left: 40px;
    column-gap: 32px;
    justify-content: start;
  }

  @media (max-width: $screen-xs) {
    margin-top: 40px;
    padding-left: 20px;
  }
}

.teamAdvisorsOverlay {
  width: 100%;
  height: 540px;
  overflow: hidden;

  @media (max-width: $screen-md) {
    width: calc(100% + 80px);
    margin-left: -40px;
    height: 490px;
  }

  @media (max-width: $screen-xs) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}

.teamAdvisor {
  display: flex;
  flex-direction: column;
  width: 268px;
  min-width: 268px;
}

.teamAdvisorPhotoOverlay {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
}

.teamAdvisorPhoto {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.teamAdvisorLink {
  margin: 28px 0 36px;
}

.teamAdvisorName {
  color: #fff;
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 20px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
}

.teamAdvisorRole {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.teamAdvisorInfo {
  color: #fff;
  display: inline-block;
  margin-bottom: 20px;
}

.howWeHolder {
  padding: 120px 0;
  background-color: #000;
  position: relative;
  z-index: 1;

  @media (max-width: $screen-md) {
    padding: 80px 0;
  }

  @media (max-width: $screen-xs) {
    padding: 60 0 36px;
  }
}

.howWeItem {
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 32px 0;
  z-index: 1;
  overflow: hidden;
  row-gap: 20px;
}

.howWeInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% - 340px) / 2);

  @media (max-width: $screen-md) {
    width: auto;
  }
}

.howWeImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.howWeTitle {
  color: #fff;
  width: 302px;
  font-size: 30px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 52px;
  font-family: 'Fira Code', monospace;

  @media (max-width: $screen-md) {
    width: auto;
  }

  @media (max-width: $screen-xs) {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 32px;

    &:only-child {
      margin-bottom: 0;
    }
  }
}

.howWeText {
  color: #fff;
  width: 302px;
  font-size: 18px;
  line-height: 29px;
  font-weight: 500;

  @media (max-width: $screen-md) {
    width: auto;
  }

  @media (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 22px;
  }
}

.howWePoint {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  min-height: 408px;
  background: #17171f;
  border-radius: 28px;
  padding: 90px 32px 32px;
  box-sizing: border-box;

  &:nth-child(1),
  &:nth-child(2) {
    width: calc(50% - 10px);
  }

  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    width: calc((100% - 40px) / 3);
  }

  @media (max-width: $screen-xl) {
    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: calc((100% - 20px) / 2);
    }
  }

  @media (max-width: $screen-md) {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 100%;
    }
  }

  @media (max-width: $screen-xs) {
    padding: 72px 24px 24px;
    min-height: 360px;
  }
}

.howWePointCount {
  position: absolute;
  top: 32px;
  left: 32px;
  color: #3d465e;
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;

  @media (max-width: $screen-xs) {
    top: 24px;
    left: 24px;
  }
}

.howWePointTitle {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;

  @media (max-width: $screen-xs) {
    margin: 4px 0 16px;
    line-height: 26px;
    font-size: 20px;
  }
}

.howWePointText {
  color: $gray;
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;

  @media (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 28px;
  }
}

.responsibilityHolder {
  padding: 40px 0 120px;
  background-color: #000;
  position: relative;
  z-index: 1;

  @media (max-width: $screen-xs) {
    padding: 0 0 36px;
  }
}

.responsibilityItem {
  position: relative;
  border-radius: 20px;
  display: flex;
  padding: 100px 170px;
  justify-content: space-between;
  background-color: #252533;
  margin: 32px 0;
  z-index: 1;
  overflow: hidden;

  &:nth-child(2) {
    margin-top: 80px;
  }

  @media (max-width: $screen-xl) {
    padding: 100px;
  }

  @media (max-width: $screen-lg) {
    padding: 80px;
  }

  @media (max-width: $screen-md) {
    flex-direction: column;
    padding: 60px;
  }

  @media (max-width: $screen-xs) {
    padding: 36px 20px;
  }
}

.responsibilityInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% - 340px) / 2);

  @media (max-width: $screen-md) {
    width: auto;
  }
}

.responsibilityImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.responsibilityTitle {
  color: #fff;
  width: 302px;
  font-size: 30px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 52px;
  font-family: 'Fira Code', monospace;

  @media (max-width: $screen-md) {
    width: auto;
  }

  @media (max-width: $screen-xs) {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 32px;

    &:only-child {
      margin-bottom: 0;
    }
  }
}

.responsibilityText {
  color: #fff;
  width: 302px;
  font-size: 18px;
  line-height: 29px;
  font-weight: 500;

  @media (max-width: $screen-md) {
    width: auto;
  }

  @media (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 22px;
  }
}

.responsibilityPoints {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% - 340px) / 2);

  @media (max-width: $screen-md) {
    width: auto;
    margin-top: 80px;
  }

  @media (max-width: $screen-xs) {
    margin-top: 60px;
  }
}

.responsibilityPoint {
  position: relative;
  margin: 32px 0;
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -32px;
    height: 1px;
    background-color: #fff;
    opacity: 0.3;
  }

  &:nth-child(1) {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;

    &:before {
      display: none;
    }
  }
}

.responsibilityPointCount {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;

  @media (max-width: $screen-xs) {
    position: relative;
    display: inline-block;
    line-height: 26px;
  }
}

.responsibilityPointTitle {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;

  @media (max-width: $screen-xs) {
    margin: 4px 0 16px;
    line-height: 26px;
  }
}

.responsibilityPointText {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.solutionsSection {
  display: flex;
  flex-wrap: wrap;
  column-gap: 60px;
  row-gap: 72px;
  max-width: 100%;
  width: 100%;
  margin-top: 40px;

  @media (max-width: $screen-xl) {
    column-gap: 40px;
    row-gap: 60px;
  }

  @media (max-width: $screen-lg) {
    column-gap: 60px;
  }

  @media (max-width: $screen-md) {
    row-gap: 32px;
  }
}

.solutionsSectionHolder {
  @extend %fullSection;
  background-color: #000;
  position: relative;
  z-index: 1;
  margin-top: 300px;

  &::before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    right: 0;
    height: 350px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 100%);
  }

  &:after {
    top: 0;
    bottom: 0;
    position: absolute;
    content: '';
    z-index: -1;
  }

  @media (max-width: $screen-md) {
    &:before,
    &:after {
      left: -40px;
      right: -40px;
    }

    &:after {
      background-color: #000;
    }
  }

  @media (max-width: $screen-sm) {
    margin-top: 120px;

    &:before {
      height: 200px;
    }
  }

  @media (max-width: $screen-xs) {
    &:before,
    &:after {
      left: -20px;
      right: -20px;
    }
  }
}

.solutionCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% - 120px) / 3);

  @media (max-width: $screen-xl) {
    width: calc((100% - 80px) / 3);
  }

  @media (max-width: $screen-lg) {
    width: calc((100% - 120px) / 2);
  }

  @media (max-width: $screen-md) {
    width: 100%;
  }
}

.solutionName {
  position: absolute;
  top: 12px;
  left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  z-index: 1;
  color: #fff;
}

.solutionTitle {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  display: inline-block;
  margin-top: 28px;

  @media (max-width: $screen-md) {
    font-size: 24px;
    line-height: 32px;
  }
}

.solutionDescription {
  font-size: 14px;
  line-height: 24px;
  color: $gray;
  margin: 28px 0;

  @media (max-width: $screen-md) {
    line-height: 28px;
    margin: 36px 0 32px;
  }
}

.solutionImage {
  position: relative;
  width: 100%;
  padding-bottom: 54%;
  border-radius: 28px;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: $screen-md) {
    height: auto;
  }
}

.solutionButton {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: $orange;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  box-sizing: border-box;
  cursor: pointer;
  margin: auto 0 0;

  svg {
    width: 55px;
    transition: 0.2s;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }

  @media (max-width: $screen-xs) {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    height: 60px;

    svg {
      width: 36px;
    }
  }
}

.trustedHolder {
  @extend %fullSection;
  background-color: #000;
  width: 100%;
  padding-bottom: 100px;
  min-height: unset;
}

.trustedHolderGap {
  padding-top: 200px;

  @media (max-width: $screen-xl) {
    padding-top: 120px;
  }

  @media (max-width: $screen-md) {
    padding-top: 80px;
  }

  @media (max-width: $screen-xs) {
    padding-top: 40px;
  }
}

.mainPageSectionTitle {
  @extend %h1;
  color: #fff;
  max-width: unset;
}

.innerPage {
  position: relative;
  z-index: 100;
}

.footerOld {
  color: #ababab;
  font-size: 14px;
  line-height: 20px;
  padding: 80px 0;
  background-color: #000;
  text-align: center;
  z-index: 1;
  position: relative;

  @media (max-width: $screen-sm) {
    padding: 0px 0 20px;
  }
}
