@import '../../../variables.scss';

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #000;
}

.content {
  position: relative;
  flex-grow: 1;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 400vw;
    background-color: #000;
    z-index: 1;
  }

  @media (max-width: $screen-xxl) {
    max-width: 1280px;
  }

  @media (max-width: $screen-xl) {
    max-width: 1020px;
  }

  @media (max-width: $screen-lg) {
    max-width: 960px;
  }

  @media (max-width: $screen-md) {
    padding: 0 40px;
  }

  @media (max-width: $screen-xs) {
    padding: 0 20px;
  }
}

.contentHolder {
  animation: showContent 0.2s;
  animation-fill-mode: forwards;
}

.loader {
  width: 100px;
  margin: auto;
  animation: hideLoader 2.5s;
  animation-fill-mode: forwards;
}

@keyframes showContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideLoader {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
