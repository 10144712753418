@import '../../../variables.scss';

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 40px 40px 0;
  box-sizing: border-box;
  z-index: 12;

  &Logo {
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    z-index: 1;

    @media (max-width: $screen-xs) {
      width: 100px;
    }
  }

  @media (max-width: $screen-xs) {
    padding: 20px 20px 0;
  }
}

%headerButton {
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.headerContact {
  @extend %headerButton;
  cursor: pointer;
  background-color: $green;
}

.headerLinks {
  display: flex;
  column-gap: 12px;
  margin: 0 24px 0 auto;

  @media (max-width: $screen-xl) {
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    margin: 0 0 16px;
    row-gap: 16px;
  }
}

.headerLink {
  @extend %headerButton;
  color: #fff;
  cursor: pointer;

  span {
    color: $gray;
    display: inline-block;
    margin-left: 4px;
    transition: 0.2s;
  }

  &Active {
    background-color: #252533;
  }

  &:hover {
    span {
      color: $green;
    }
  }
}

.headerMenu {
  display: inline-block;
  margin: 0 0 0 auto;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.phoneHeaderMenu {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 100px 20px 28px;
  box-sizing: border-box;
  background-color: #000;
  align-items: flex-end;
}

%tag {
  display: inline-block;
  border-radius: 8px;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16.25px;
  font-family: 'Fira Code', monospace;
  margin-left: 16px;

  @media (max-width: $screen-xs) {
    display: none;
  }
}

.educationTag {
  @extend %tag;
  color: $orange;
  background: #ee632842;
}

.engineeringTag {
  @extend %tag;
  color: $blue;
  background: #4e75ff4d;
}

.marketersTag {
  @extend %tag;
  color: #c759d9;
  background: #c759d942;
}

.assistantsTag {
  @extend %tag;
  color: #ff7575;
  background: #ff757542;
}

.nursesTag {
  @extend %tag;
  color: #71c348;
  background: #71c3484d;
}
