@import '../../../variables.scss';

.mainScreenSection {
  @extend %fullSection;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 10;

  @media (max-width: $screen-md) {
    padding: 0 80px;
  }

  @media (max-width: $screen-sm) {
    padding: 0 40px;
  }

  @media (max-width: $screen-xs) {
    padding: 0;
  }
}

.mainScreenText {
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  font-family: 'Fira Code', monospace;
  margin-top: 56px;

  @media (max-width: $screen-sm) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
  }
}

.mainScreenTitle {
  @extend %h1;
  color: #fff;
  margin: 32px auto 52px;

  @media (max-width: $screen-xxl) {
    margin: 20px auto 40px;
  }
}

.mainScreenButton {
  cursor: pointer;
  background: #0effd4;
  border-radius: 12px;
  padding: 23px 60px;
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1160px;
  font-weight: 600;
  text-align: left;
  font-size: 30px;
  font-family: 'Fira Code', monospace;
  box-sizing: border-box;
  line-height: 1;
  transition: 0.2s;

  &:hover {
    background-color: $green-05;

    .mainScreenButtonIcon {
      transform: translateX(15px);
    }
  }

  @media (max-width: $screen-sm) {
    font-size: 16px;
    padding: 16px 20px;

    &:hover {
      .mainScreenButtonIcon {
        transform: translateX(10px);
      }
    }
  }
}

.mainScreenButtonIcon {
  width: 36px;
  transition: 0.2s;

  @media (max-width: $screen-sm) {
    width: 26px;
  }
}
