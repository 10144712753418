@import '../../../variables.scss';

.firstScreen {
  display: flex;
  column-gap: 200px;
  margin-top: 240px;

  @media (max-width: $screen-xxl) {
    margin-top: 140px;
  }

  @media (max-width: $screen-xl) {
    column-gap: 120px;
  }

  @media (max-width: $screen-lg) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.firstScreenData {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.firstScreenTitle {
  font-size: 52px;
  font-weight: 500;
  line-height: 60px;
  font-family: 'Fira Code', monospace;
  color: #fff;

  @media (max-width: $screen-lg) {
    text-align: center;
  }

  @media (max-width: $screen-sm) {
    font-size: 36px;
    line-height: 40px;
    margin: 40px 0 0;
  }
}

.firstScreenText {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  display: inline-block;
  margin: 10px 0 60px;

  @media (max-width: $screen-lg) {
    text-align: center;
  }

  @media (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 30px;
  }
}

.firstScreenImage {
  width: 515px;
  height: 710px;
  border-radius: 36px;
  flex-shrink: 0;
  overflow: hidden;

  @media (max-width: $screen-xl) {
    width: 400px;
    height: auto;
  }

  @media (max-width: $screen-lg) {
    width: 100%;
    height: 48vw;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
}

.firstScreenButton {
  border-radius: 12px;
  height: 68px;
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
  padding: 0 60px;
  align-items: center;
  font-weight: 500;
  font-family: 'Fira Code', monospace;
  font-size: 26px;
  line-height: 36px;
  cursor: pointer;

  @media (max-width: $screen-sm) {
    font-size: 20px;
    line-height: 32px;
    padding: 0 20px;
    height: 60px;
  }

  svg {
    width: 35px;
    transition: 0.2s;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
}

.firstScreenTumbler {
  width: 204px;
  height: 44px;
  border-radius: 12px;
  background: #2c2c3d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  @media (max-width: $screen-lg) {
    align-self: center;
  }
}

.firstScreenTumblerItem {
  background: #2c2c3d;
  color: #81819a;
  padding: 6px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
}

.firstScreenTumblerItemActive {
  background-color: #fff;
  color: #000;
}
