@import '../../../variables.scss';

.serviceCard {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 28px;
  background: #17171f;
  flex-grow: 1;

  @media (max-width: $screen-xs) {
    padding: 24px;
  }
}

.serviceCardIcon {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $screen-xs) {
    width: 42px;
    height: 42px;
  }
}

.serviceCardTitle {
  display: inline-block;
  margin: 0 0 16px;
  color: #fff;
  font-family: Fira Code;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  max-width: calc(100% - 84px);

  @media (max-width: $screen-xs) {
    font-size: 20px;
    margin: 24px 0 16px;
  }
}

.serviceCardText {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #7a868f;
  display: inline-block;
  max-width: calc(100% - 84px);
  margin-bottom: 40px;

  @media (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 24px;
  }
}

.serviceCardTags {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px;
  margin: auto 0 0;

  @media (max-width: $screen-xs) {
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 24px;
  }
}

.serviceCardTag {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  padding: 4px 12px;
  border-radius: 10px;
  background: #2c2c3d;
  color: #9d9da7;

  @media (max-width: $screen-xs) {
    font-size: 14px;
    padding: 2px 8px;
  }
}
