@import '../../../variables.scss';

.blogPageContent {
  display: flex;
  flex-direction: column;
  margin: 132px auto 52px;
  max-width: 800px;

  @media (max-width: $screen-md) {
    max-width: calc(100% - 40px);
  }
}

.blogPostHeader {
  position: relative;
  padding-bottom: 52.5%;
  border-radius: 18px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 0 0;
  }
}

.blogPostTitle {
  position: absolute;
  font-family: Fira Code;
  font-size: 34px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  bottom: 34px;
  left: 26px;
  // color: #fff;
  color: transparent;
  width: 320px;
  max-width: 45%;
  z-index: 1;
  margin: 0 !important;

  @media (max-width: $screen-md) {
    font-size: 15px;
    line-height: 17px;
  }

  @media (max-width: $screen-sm) {
    bottom: 16px;
    left: 12px;
  }
}

.blogPostTitleTop {
  bottom: unset;
  top: 34px;

  @media (max-width: $screen-sm) {
    bottom: unset;
    top: 16px;
  }
}

.blogPostText {
  color: #a4acc2;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-top: 32px;

  h1,
  h2,
  h3,
  h4 {
    color: #fff;
  }

  a {
    color: $green !important;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  strong {
    color: #fff;
  }

  ol,
  ul {
    padding-left: 16px;
    margin: 32px 0;
  }

  li {
    margin-bottom: 32px;

    &::marker {
      color: #fff;
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -22px;
      width: 3px;
      background-color: #a4acc2;
    }
  }
}

.blogPostTags {
  margin: 32px 0;
}

.blogPostTag {
  font-style: italic;
  color: #a4acc2;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
}

.blogMoreTitle {
  font-family: Fira Code;
  font-size: 44px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  color: #fff;
  margin: 52px 0 32px;

  @media (max-width: $screen-sm) {
    font-size: 28px;
    line-height: 40px;
    margin: 32px 0;
  }
}

.blogPageLoader {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blogPostCardImage {
  position: relative;
  padding-bottom: 54%;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid #252532;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
}
