@import '../../../variables.scss';

.mainPointsHolder {
  display: flex;
  column-gap: 48px;
  margin-top: 100px;

  @media (max-width: $screen-xl) {
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 44px;
  }
}

.mainPoint {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc((100% - 144px) / 4);

  @media (max-width: $screen-xl) {
    width: calc((100% - 32px) / 2);
  }

  @media (max-width: $screen-sm) {
    width: 100%;
  }
}

.mainPointIcon {
  width: 52px;
  height: 52px;
}

.mainPointTitle {
  color: #fff;
  margin: 32px 0 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
}

.mainPointText {
  color: $gray;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;

  @media (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 28px;
  }
}
