@import '../../../variables.scss';

.footer {
  padding: 80px 0;
  font-size: 16px;
  line-height: 28px;
  position: relative;
  z-index: 10;
  background-color: #000;

  span {
    color: $gray;
  }

  .content {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 40px;
    align-items: center;
    text-align: center;

    &:before {
      display: none !important;
    }
  }

  @media (max-width: $screen-sm) {
    padding: 40px 0;
  }
}

.footerLinks {
  display: flex;
  column-gap: 32px;

  @media (max-width: $screen-xxl) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 12px;
  }
}

.footerLink {
  color: #fff !important;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: $green !important;
  }
}
