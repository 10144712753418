@import '../../../variables.scss';

.headerOld {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 40px 40px 0;
  box-sizing: border-box;
  z-index: 12;
  align-items: flex-start;

  @media (max-width: $screen-md) {
    padding-top: 16px;
    flex-wrap: wrap;
    height: auto;

    &:before {
      height: 111px;
    }

    &-nav {
      width: 100%;
      order: 3;
      text-align: center;
      margin: 20px 0;
      justify-content: center;
    }

    &-link {
      &:before {
        bottom: -23px;
      }
    }
  }

  @media (max-width: $screen-sm) {
    padding: 16px 40px 0;
  }

  @media (max-width: $screen-xs) {
    padding: 16px 20px 0;

    &-link {
      margin-right: 15px;
      font-size: 15px;
    }

    &-logo {
      width: 224px;
      margin-right: 0;
    }
  }

  & .headerLogo {
    display: flex;
    align-items: center;
    column-gap: 8px;

    @media (max-width: $screen-xs) {
      width: 120px;
    }
  }

  & .headerDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;

    @media (max-width: $screen-lg) {
      padding: 0 0 20px 20px;
      background-color: #000;
      box-shadow: -29px 22px 23px 5px #000000;
    }

    @media (max-width: $screen-xs) {
      position: absolute;
      top: 12px;
      right: 20px;

      &:before {
        position: absolute;
        top: 0;
        right: -20px;
        bottom: 0;
        left: 0;
        z-index: -1;
        content: '';
        background-color: #000;
        box-shadow: 10px 22px 23px 5px #000000;
      }
    }
  }

  & .headerContacts {
    background: #252533;
    color: #fff;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 28px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: #32334a;
    }

    @media (max-width: $screen-xs) {
      font-size: 14px;
    }
  }

  .headerMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 24px;

    &Item {
      cursor: pointer;
      color: #fff;
      font-size: 15px;
      line-height: 1;
      font-weight: 500;
      transition: 0.2s;

      &:hover {
        color: $green;

        span {
          color: $green;
        }
      }

      span {
        color: #6a6b83;
        margin-left: 8px;
      }

      @media (max-width: $screen-xs) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    @media (max-width: $screen-xs) {
      row-gap: 16px;
    }
  }
}
