@import '../../../variables.scss';

.notFoundPage {
  width: 100%;
  max-width: 584px;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  margin: 200px auto;
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: $screen-sm) {
    margin: 120px auto 60px;
  }
}

.notFoundPageText {
  display: inline-block;
  margin: 60px 0;
  font-size: 20px;
  line-height: 32px;

  @media (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 28px;
    margin: 32px 0;
  }
}

.notFoundPageButton {
  display: flex;
  padding: 0 28px;
  height: 68px;
  align-items: center;
  background-color: #71c348;
  color: #000;
  border-radius: 12px;
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  column-gap: 60px;
  font-family: 'Fira Code', monospace;
  cursor: pointer;

  @media (max-width: $screen-sm) {
    font-size: 20px;
    line-height: 32px;
    column-gap: 20px;
  }
}

.notFoundPageButtonIcon {
  width: 35px;
}
