$screen-xxs: 320px;
$screen-xs: 600px;
$screen-sm: 800px;
$screen-md: 1024px;
$screen-lg: 1200px;
$screen-xl: 1440px;
$screen-xxl: 1780px;

$green: #0effd4;
$green-05: #04e0b9;
$orange: #ee6328;
$blue: #4366e3;
$gray: #97a7b2;

%section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

%fullSection {
  @extend %section;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
}

%h1 {
  font-weight: 500;
  font-size: 52px;
  line-height: 60px;
  max-width: 1160px;
  font-family: 'Fira Code', monospace;

  @media (min-width: $screen-xxl) {
    font-size: 88px;
    line-height: 92px;
    max-width: 1060px;
  }

  @media (max-width: $screen-xl) {
    max-width: 986px;
  }

  @media (max-width: $screen-xs) {
    font-size: 30px;
    line-height: 40px;
  }
}
